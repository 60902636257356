import axios from "axios";
import store from "../../../store/index";

export default {
  get3dModel(payload) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.getters["auth/user"].token}`,
      }
    };
    return axios.post(`${process.env.VUE_APP_FG_SERVICE_URL}/render/`, payload, config);
  }
}
