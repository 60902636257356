import { WebGLRenderer, PerspectiveCamera, Scene, Color, FogExp2, GridHelper, PointLight, Vector3, BufferGeometry, Line, LineBasicMaterial, Mesh } from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
export default {
  SET_VIEWPORT_SIZE(state, { width, height }) {
    state.width = width;
    state.height = height;
  },
  INITIALIZE_RENDERER(state, el) {
    state.renderer = new WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
    });
    state.renderer.setPixelRatio(window.devicePixelRatio);
    state.renderer.setSize(state.width, state.height);
    el.appendChild(state.renderer.domElement);
  },
  INITIALIZE_CAMERA(state) {
    state.camera = new PerspectiveCamera(
      60,
      state.width / state.height,
      1,
      1000
    );
    state.camera.position.set(10, 10, 10);
  },
  INITIALIZE_CONTROLS(state) {
    state.controls = new TrackballControls(
      state.camera,
      state.renderer.domElement
    );
    state.controls.rotateSpeed = 1.0;
    state.controls.zoomSpeed = 1.2;
    state.controls.panSpeed = 0.8;
    state.controls.noZoom = false;
    state.controls.noPan = false;
    state.controls.staticMoving = true;
    state.controls.dynamicDampingFactor = 0.3;
  },
  INITIALIZE_SCENE(state) {
    state.scene = new Scene();
    state.scene.background = new Color(0xcccccc);
    state.scene.fog = new FogExp2(0xcccccc, 0.002);
    state.grid = new GridHelper(100, 100);
    state.scene.add(state.grid);
    const pointLight = new PointLight(0xFFFFFF);
    pointLight.position.x = 10;
    pointLight.position.y = 50;
    pointLight.position.z = 130;
    state.scene.add(pointLight)
    // Axis Line 1
    const materialA = new LineBasicMaterial({ color: 0x0000ff });
    const pointsA = [];
    pointsA.push(new Vector3(0, 0, 0));
    pointsA.push(new Vector3(0, 50, 0));
    const geometryA = new BufferGeometry().setFromPoints(pointsA);
    const lineA = new Line(geometryA, materialA);
    state.axisLines.push(lineA);
    // Axis Line 2
    const materialB = new LineBasicMaterial({ color: 0x00ff00 });
    const pointsB = [];
    pointsB.push(new Vector3(0, 0, 0));
    pointsB.push(new Vector3(50, 0, 0));
    const geometryB = new BufferGeometry().setFromPoints(pointsB);
    const lineB = new Line(geometryB, materialB);
    state.axisLines.push(lineB);
    // Axis 3
    const materialC = new LineBasicMaterial({ color: 0xff0000 });
    const pointsC = [];
    pointsC.push(new Vector3(0, 0, 0));
    pointsC.push(new Vector3(0, 0, -50));
    const geometryC = new BufferGeometry().setFromPoints(pointsC);
    const lineC = new Line(geometryC, materialC);
    state.axisLines.push(lineC);
    state.scene.add(...state.axisLines);
  },
  RESIZE(state, { width, height }) {
    state.width = width;
    state.height = height;
    state.camera.aspect = width / height;
    state.camera.updateProjectionMatrix();
    state.renderer.setSize(width, height);
    state.controls.handleResize();
    state.renderer.render(state.scene, state.camera);
  },
  SET_CAMERA_POSITION(state, { x, y, z }) {
    if (state.camera) {
      state.camera.position.set(x, y, z);
    }
  },
  RESET_CAMERA_ROTATION(state, { x, y, z }) {
    if (state.camera) {
      state.camera.rotation.set(0, 0, 0);
      state.camera.quaternion.set(0, 0, 0, 1);
      state.camera.up.set(0, 1, 0);
      state.controls.target.set(x, y, z);
    }
  },
  TOGGLE_GRIDS(state, showGrid) {
    if (showGrid) {
      state.scene.add(state.grid);
    } else {
      state.scene.remove(state.grid);
    }
    state.renderer.render(state.scene, state.camera);
  },
  TOGGLE_AXIS_LINES(state, showAxisLines) {
    if (showAxisLines) {
      state.scene.add(...state.axisLines);
    } else {
      state.scene.remove(...state.axisLines);
    }
    state.renderer.render(state.scene, state.camera);
  },
  SAVE_MODEL(state, payload) {
    state.model = JSON.stringify(payload);
  },
  INIT_MODEL(state) {
    state.scene.remove(state.mesh);
  },
  INITIALIZE_PARSER(state) {
    state.mesh = new Mesh();
    state.loader = new GLTFLoader();
    state.loader.parse(state.model, "", (gltf) => {
      state.mesh = gltf.scene || gltf.scenes[0];
      gltf.scene.scale.set(50, 50, 50);
      gltf.scene.position.set(0, 0, 0);
      gltf.scene.rotation.set(0, 0, 0);
      gltf.scene.castShadow = false;
      gltf.scene.receiveShadow = false;
      state.scene.add(gltf.scene);
    });
    state.camera.position.set(18, 12, 8);
    state.controls.target.set(7, 2, -3);
  },
  SAVE_DATA_URL(state, payload) {
    state.dataUrl = payload;
  },
  INITIALIZE_LOADER(state, url) {
    state.mesh = new Mesh();
    state.loader = new GLTFLoader();
    state.loader.load(url, (gltf) => {
      state.mesh = gltf.scene || gltf.scenes[0];
      gltf.scene.scale.set(50, 50, 50);
      gltf.scene.position.set(0, 0, 0);
      gltf.scene.rotation.set(0, 0, 0);
      gltf.scene.castShadow = false;
      gltf.scene.receiveShadow = false;
      state.scene.add(gltf.scene);
    });
    state.camera.position.set(18, 12, 8);
    state.controls.target.set(7, 2, -3);
  },
  INITIALIZE_STATE(state) {
    state.width = 0;
    state.height = 0;
    state.mesh = null;
    state.grid = null;
    state.loader = null;
    state.axisLines.splice(0);
    state.scene = null;
    state.camera = null;
    state.controls = null;
    state.renderer = null;
    state.dataUrl = null;
    state.animationId = null;
    state.model = null;
  },
};
