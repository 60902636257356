<template>
  <v-container fluid>
    <base-page-title
      >Fixture Design 3D Render: Fixture Design({{ fdId }})
      {{ partNumber }}</base-page-title
    >
    <v-toolbar v-if="stateDescription" dense flat>
      <div>
        Fixture Design State:
        {{ fixtureStateName }}
        - {{ stateDescription }}
      </div>
    </v-toolbar>
    <v-sheet outlined>
      <v-container fluid>
        <v-row>
          <DutConfigMainForm
            :dutConfigsList="dutConfigsList"
            :defaultDut="dutId ? dutId : dutConfigsList[0].pk"
            :renderSet="renderSet"
            :isInitialState="isInitialState"
            :isAdmin="isAdmin"
            @generate="onGenerate"
            @configure="
              gotoConfigure(
                '/configure/project/' +
                  $route.params.id +
                  '/dutconfig/' +
                  currentDutId
              )
            "
            @update="onUpdate"
            @updateDutId="onUpdateDutId"
          />
          <v-col cols="12" md="8">
            <ControlPanel />
            <div class="viewport" ref="viewport" />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-container>
</template>
<script>
import viewPortModule from "../store";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "FixturConfigPage",
  props: {
    dutId: {
      type: Number,
      default: null,
    },
  },
  components: {
    DutConfigMainForm: () => import("../components/DutConfigMainForm.vue"),
    ControlPanel: () => import("../components/ControlPanel.vue"),
  },
  data() {
    return {
      currentDutId: null,
    };
  },
  computed: {
    ...mapGetters({
      dutConfigsList: "dutconfigs/dutConfigsList",
      fixtureDesign: "fixturedesign/fixtureDesign",
      fdState: "fixturedesign/fdState",
      selectedProject: "projects/selProject",
      renderModel: "viewport/renderModel",
      dataUrl: "viewport/dataUrl",
      miniVariant: "ui/miniVariant",
      fdStateLongList: "ui/fdStateLongList",
      isAdmin: "auth/isAdmin",
    }),
    isInitialState() {
      return this.fixtureDesign.state === 1;
    },
    fdId() {
      return this.fixtureDesign.pk;
    },
    fixtureState() {
      return this.fixtureDesign.state;
    },
    fixtureStateName() {
      const result = this.fdState.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : "";
    },
    stateDescription() {
      const result = this.fdStateLongList.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : null;
    },
    partNumber() {
      return this.fixtureDesign.part ? this.fixtureDesign.part.number : "";
    },
    activeDFP() {
      const record = this.fixtureDesign.design_file_packages.find(
        (element) => element.active
      );
      return record ? record : null;
    },
    renderSet() {
      return this.activeDFP ? this.activeDFP.render_setting : null;
    },
  },
  methods: {
    ...mapMutations({ resize3: "viewport/RESIZE" }),
    ...mapActions({
      initialize3: "viewport/INIT",
      animate3: "viewport/ANIMATE",
      reload3: "viewport/RELOAD",
      fetchProject: "projects/fetchProject",
      updateDutConfigs: "dutconfigs/updateDutConfigs",
      fetchTPOptions: "dutconfigs/getTestPointOptions",
      fetchFDOptions: "dutconfigs/getFixtureDesignOptions",
      initDutConfigsState: "dutconfigs/initFixtureRenderState",
      fetchFixtureDesign: "fixturedesign/getFixtureDesign",
      patchFixtureDesign: "fixturedesign/patchFixtureDesign",
      genFixtureDesign: "fixturedesign/generateFixtureDesign",
      initFixtureDesignState: "fixturedesign/initFixtureDesignState",
      loadRenderModel: "viewport/loadRenderView",
      parseRenderModel: "viewport/parseRenderView",
      initViewportState: "viewport/initViewportState",
      toggleLoading: "ui/loading",
      updateDesignFilePackage: "fixturedesign/patchDesignFilePackage",
    }),
    resizeHandler() {
      this.resize3({
        width: this.$refs.viewport.offsetWidth,
        height: this.$refs.viewport.offsetHeight,
      });
    },
    async onUpdate({ payload, formUpdated }) {
      try {
        if (formUpdated) {
          this.toggleLoading("Fetching Fixture Design");
          await Promise.all([
            this.updateDutConfigs(payload),
            this.updateDesignFilePackage({
              id: this.activeDFP.id,
              data: { render_setting: payload.render_setting },
            }),
          ]);
          await this.fetchFixtureDesign({
            params: { project_id: this.$route.params.id },
          });
          this.toggleLoading();
        }
        if (
          this.activeDFP.render_file_generation &&
          !formUpdated &&
          !this.fixtureDesign.update_render
        ) {
          await this.loadRenderModel();
        } else {
          await this.parseRenderModel();
        }
        if (
          !this.activeDFP.render_file_generation ||
          formUpdated ||
          this.fixtureDesign.update_render
        ) {
          this.toggleLoading("Preparing thumbnail");
          setTimeout(async () => {
            const thumb3d = await this.captureImage();
            let designData = new FormData();
            designData.append(
              "render_file_generation",
              new Blob([this.renderModel], { type: "application/json" }),
              "model.gltf"
            );
            designData.append("render_setting", payload.render_setting);
            designData.append("thumbnail", thumb3d, "thumbnail3d.jpg");
            await this.updateDesignFilePackage({
              id: this.activeDFP.id,
              data: designData,
            });
            this.toggleLoading();

            if (this.fixtureDesign.update_render) {
              let inputData = new FormData();
              inputData.append("update_render", false);
              await this.patchFixtureDesign({
                pk: this.fixtureDesign.pk,
                data: inputData,
              });
            }

            await this.fetchFixtureDesign({
              params: { project_id: this.$route.params.id },
            });
          }, 10000);
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async onGenerate() {
      try {
        this.$router.push("/fulfillment/project/" + this.$route.params.id);
        if (this.isAdmin) {
          const response = await this.genFixtureDesign(this.fixtureDesign.pk);
          this.$store.commit("ui/SNACK_BAR", response);
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to authenticate, try later."
        );
      }
    },
    async captureImage() {
      return new Promise((resolve) => {
        const canvas = document.getElementsByTagName("canvas")[0];
        const dataUrl = canvas.toDataURL("image/jpeg");
        this.$store.commit("viewport/SAVE_DATA_URL", dataUrl);
        let arr = dataUrl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const imageBlob = new Blob([u8arr], { type: mime });
        if (imageBlob) resolve(imageBlob);
      });
    },
    onUpdateDutId(id) {
      this.currentDutId = id;
    },
    gotoConfigure(link) {
      this.$router.push(link);
    },
  },
  async created() {
    this.toggleLoading("Loading Fixture Design");
    try {
      await Promise.all([
        this.fetchProject(this.$route.params.id),
        this.fetchTPOptions(),
        this.fetchFDOptions(),
      ]);
      this.toggleLoading();
      this.onUpdate({
        payload: { render_setting: this.activeDFP.render_setting },
        formUpdated: false,
      });
    } catch (err) {
      this.toggleLoading();
      this.$store.commit(
        "ui/SNACK_BAR",
        err.message || "Failed to process request, try later."
      );
    }
  },
  async mounted() {
    if (!this.$store.hasModule("viewport"))
      this.$store.registerModule("viewport", viewPortModule);
    if (!this.miniVariant) this.$store.commit("ui/MINI_VARIANT");
    this.initialize3({
      width: this.$refs.viewport.offsetWidth,
      height: this.$refs.viewport.offsetHeight,
      el: this.$refs.viewport,
    }).then(() => {
      this.animate3();
      window.addEventListener("resize", this.resizeHandler);
    });
    this.currentDutId = this.dutId ? this.dutId : this.dutConfigsList[0].pk;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
    this.initViewportState();
    if (this.miniVariant) this.$store.commit("ui/MINI_VARIANT");
  },
};
</script>
