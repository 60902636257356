export default {
  cameraPosition(state) {
    return state.camera ? state.camera.position : null;
  },
  dataUrl(state) {
    return state.dataUrl;
  },
  renderModel(state) {
    return state.model;
  },
};