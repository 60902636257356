export default () => ({
  width: 0,
  height: 0,
  camera: null,
  controls: null,
  scene: null,
  renderer: null,
  mesh: null,
  grid: null,
  loader: null,
  axisLines: [],
  dataUrl: null,
  animationId: null,
  model: null,
})