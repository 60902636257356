import RenderService from "./endpoints";

export default {
  INIT(context, { width, height, el }) {
    return new Promise((resolve) => {
      context.commit("SET_VIEWPORT_SIZE", { width, height });
      context.commit("INITIALIZE_RENDERER", el);
      context.commit("INITIALIZE_CAMERA");
      context.commit("INITIALIZE_CONTROLS");
      context.commit("INITIALIZE_SCENE");
      context.state.renderer.render(context.state.scene, context.state.camera);
      context.state.controls.addEventListener("change", () => {
        context.state.renderer.render(
          context.state.scene,
          context.state.camera
        );
      });
      resolve();
    });
  },
  ANIMATE(context) {
    context.state.animationId = window.requestAnimationFrame(() => {
      context.dispatch("ANIMATE");
      context.state.controls.update();
      context.state.renderer.render(context.state.scene, context.state.camera);
    });
  },
  initViewportState(context) {
    window.cancelAnimationFrame(context.state.animationId);
    context.commit("INITIALIZE_STATE");
  },
  async loadRenderView(context) {
    context.dispatch("ui/loading", "Loading 3D Model", { root: true });
    const activeDFP = context.rootGetters[
      "fixturedesign/designFilePackages"
    ].find((element) => element.active);
    if (
      context.state.loader &&
      (context.state.model || (activeDFP && activeDFP.render_file_generation))
    ) {
      context.commit("INIT_MODEL");
      context.commit("SET_CAMERA_POSITION", { x: 10, y: 10, z: 10 });
      context.commit("RESET_CAMERA_ROTATION", { x: 0, y: 0, z: 0 });
    }
    await context.commit("INITIALIZE_LOADER", activeDFP.render_file_generation);
    context.dispatch("ui/loading", null, { root: true });
  },
  async parseRenderView(context) {
    context.dispatch("ui/loading", "Parsing 3D Model", { root: true });
    const activeDFP = context.rootGetters[
      "fixturedesign/designFilePackages"
    ].find((element) => element.active);
    if (
      context.state.loader &&
      (context.state.model || (activeDFP && activeDFP.render_file_generation))
    ) {
      context.commit("INIT_MODEL");
      context.commit("SET_CAMERA_POSITION", { x: 10, y: 10, z: 10 });
      context.commit("RESET_CAMERA_ROTATION", { x: 0, y: 0, z: 0 });
    }
    try {
      const response = await RenderService.get3dModel(
        context.rootGetters["fixturedesign/fixtureDesign"]
      );
      console.log("RENDER", response);
      context.commit("SAVE_MODEL", response.data);
      context.commit("INITIALIZE_PARSER");
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  errorHandler(context, err) {
    let error = new Error("Failed to process request. Please try again later.");
    if (err.response.status !== undefined) {
      if (err.response.status === 500) {
        error = new Error(
          "We encountered an internal issue, please email support@fixturfab.com."
        );
        throw error;
      } else {
        Object.keys(err.response.data).forEach((key) => {
          if (key !== undefined) {
            error = new Error(err.response.data[key] + "(" + key + ")");
            throw error;
          } else {
            throw error;
          }
        });
      }
    } else {
      throw error;
    }
  },
};
